<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <!-- <h2>Overdue Jobs
                    </h2> -->
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                        </li>
                        <li>Overdue Jobs
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list ptb-50">
    <div >
        <div class="row">

            <div class="col-lg-12 col-md-12">
             
                <input class="form-control" style="width: 300px; margin-left: 963px; margin-top: -40px;" (keyup)="applyFilter($event)" placeholder="Enter Text To Search">

                <div class="card mat-elevation-z6 m-3 w-auto">
                    <table mat-table [dataSource]="dataSource" matSort class="table-responsive">
                      <ng-container matColumnDef="JobRefrenceNo" >
                        <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;"> Job Reference No </th>
                        <td mat-cell *matCellDef="let element" (click)="JobClick(element)"> {{element.JobNumber}} </td>
                      </ng-container>
                
                      <ng-container matColumnDef="JobRole">
                        <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;"> Job Role </th>
                        <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.JobRole}} </td>
                      </ng-container>
                
                      <ng-container matColumnDef="CompanyName">
                        <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;"> Company Name </th>
                        <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.CompanyName}} </td>
                      </ng-container>
                
                      <ng-container matColumnDef="JobPostedDate">
                        <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;"> Job Posted Date </th>
                        <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.JobPostedDate | date : 'dd-MM-yyyy'}} </td>
                      </ng-container>
                
                      <ng-container matColumnDef="No.OfOpening">
                        <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;">No. Of Opening</th>
                        <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.JobOpening}} </td>
                      </ng-container>

                      <ng-container matColumnDef="JobStatus">
                        <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;">Job Status</th>
                        <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.JobstatusName}} </td>
                      </ng-container>
                      
                      <ng-container matColumnDef="RecruiterName">
                        <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;">Owner</th>
                        <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.RecruiterName}} </td>
                      </ng-container>
                  
                      <ng-container matColumnDef="TargetDate">
                        <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;">Target Date</th>
                        <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.TargetDate | date:'dd-MM-yyyy'}} </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                
                    <mat-paginator color="primary" [pageSize]="10" [pageSizeOptions]="[5, 10, 15, 25, 100]" aria-label="Select page"
                      >
                    </mat-paginator>
            </div>

        </div>
    </div>
</div>


<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->