import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import { SharedService } from 'src/app/shared.service';
import { MatTableDataSource } from '@angular/material/table';
import { Jobpost, UplodJob } from 'src/app/interface';
import { MatPaginator } from '@angular/material/paginator';
import { GlobalService } from 'src/app/global.service';
import { AuthService } from 'src/app/auth.service';
import { ExcelServicesService } from 'src/app/excel-services.service';


@Component({
  selector: 'app-overduejob',
  templateUrl: './overduejob.component.html',
  styleUrls: ['./overduejob.component.scss']
})
export class OverdueJobComponent implements OnInit, OnDestroy {

  displayedColumns = ["JobRefrenceNo", "JobRole", "CompanyName", "JobPostedDate", "No.OfOpening", "JobStatus","RecruiterName","TargetDate"];
  jobData: any;
  dataSource: MatTableDataSource<Jobpost[]>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  data: any;

  constructor(
    private toastr: ToastrService,
    private service: SharedService<any>,
  ) { }

  ngOnInit(): void {
    this.getManagejob()
  }

  getManagejob() {
      this.service.Get('Job/GetOverdueJob?Id=0', null).subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
    },
      (error) => {
        this.toastr.error(error.error);
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy(): void {

  }

}
