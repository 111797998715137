import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GlobalService } from 'src/app/global.service';
import { AcademicDiscipline, cities, JobRole, Qualification, Qualificationa, Specialization } from 'src/app/interface';
import { SharedService } from 'src/app/shared.service';
import { GenralDialogComponent } from '../input -dialog/genral-dialog.component';



export class Wpmsginf {
  MobileNo: string;
  Message: string;
}

@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.scss']
})


export class CandidatesComponent implements OnInit, OnDestroy {

  candidatsInfo = []
  datapageInfo: any;
  candiateSkillInfo = [];
  dataFilter: number[] = [];
  data: any;
  jobRoleControl = new FormControl();
  control = new FormControl();
  skillControl = new FormControl();
  JobRole: number = 0;


  // allspecialization: Observable<Specialization[]>;



  Education: number = 0;
  City: number = 0;
  Specialization: number = 0;
  AcademicDiscipline: number = 0;
  Function: number = 0;
  Section: number = 0;
  page: number = 1;
  pagesize: number = 10;
  totalItems: number = 0;
  skillinfo: string = '';
  candidateForm: FormGroup;
  totalpages: number;
  displaytotalpage: number
  console: any;
  allJobRole: JobRole[];
  filterJobRole?: Observable<JobRole[]>;
  formControlObj = new FormControl();

  allCities: cities[];
  filtercity?: Observable<cities[]>;
  formControlcity = new FormControl();

  allQualification?: Qualification[];
  filterQuli?: Observable<Qualification[]>;
  formControlQuli = new FormControl();

  alldiscipline: AcademicDiscipline[];
  filterdiscipline?: Observable<AcademicDiscipline[]>;
  formControldiscipline = new FormControl();

  allspecialization: Specialization[];
  filterspecialization?: Observable<Specialization[]>;
  formControlspecialization = new FormControl();

  JobRoleId: any;

  allfunction: any[];
  filterfunction?: Observable<any[]>;
  formControlfunction = new FormControl();


  allsection: any[];
  filtersection?: Observable<any[]>;
  formControlsection = new FormControl();

  constructor(private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private router: Router,
    public myGlobal: GlobalService,
    private service: SharedService<any>,
    private dialog: MatDialog,
    private Service: SharedService<any>,
  ) {
    this.dataFilter = [];
    this.dataFilter.push(this.myGlobal.getuserId());
    this.dataFilter.push(this.myGlobal.GetUserType());
    this.dataFilter.push(this.myGlobal.CurJobRole);
    this.dataFilter.push(this.myGlobal.CurCity);
    this.dataFilter.push(this.myGlobal.CurQualification);
    this.dataFilter.push(this.myGlobal.CurSpecialization);
    this.dataFilter.push(this.myGlobal.CurAcademicDecipline);
    this.dataFilter.push(this.myGlobal.CurFunction);
    this.dataFilter.push(this.myGlobal.CurIndustryType);

  }

  ngOnInit(): void {

    this.dataFilter.push(this.myGlobal.FindCandidatePage);
    this.dataFilter.push(this.pagesize);
    this.refreshList();

    this.candidateForm = this.formBuilder.group({
      JobRoleId: [null, Validators.nullValidator],
      CityId: [null, Validators.nullValidator],
      QualificationId: [null, Validators.nullValidator],
      AcademicDisciplineId: [null, Validators.nullValidator],
      SpecializationId: [null, Validators.nullValidator],
      FunctionId: [null, Validators.nullValidator],
      SectionId: [null, Validators.nullValidator],
      PageSearch: [null, Validators.nullValidator],
      SearchEmail: [null, Validators.nullValidator],
      SearchMobileNo: [null, Validators.nullValidator],
    });



    this.service.Get('JobRole/GetJobRole?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allJobRole = data;
        this.filterJobRole = this.formControlObj.valueChanges.pipe(
          startWith<string | JobRole>(''),
          map(value => typeof value === 'string' ? value : value.JobRole),
          map(JobRole => JobRole ? this._filterRole(JobRole) : this.allJobRole.slice()),
        );
      }
    })


    this.service.Get('City/GetCity?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allCities = data;
        this.filtercity = this.formControlcity.valueChanges.pipe(
          startWith<string | cities>(''),
          map(value => typeof value === 'string' ? value : value.CityName),
          map(CityName => CityName ? this._filtercity(CityName) : this.allCities.slice()),
        );
      }
    })


    this.service.Get('Qualification/GetQualification?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allQualification = data;
        this.filterQuli = this.formControlQuli.valueChanges.pipe(
          startWith<string | Qualification>(''),
          map(value => typeof value === 'string' ? value : value.Qualification),
          map(Qualification => Qualification ? this._filterQuali(Qualification) : this.allQualification.slice()),
        );
      }
    })


    this.service.Get('AcademicDiscipline/GetAcademicDisciplineByQualification?QualificationId=' + this.myGlobal.CurQualification, null).subscribe(data => {
      if (data != null) {
        this.alldiscipline = data;
        this.filterdiscipline = this.formControldiscipline.valueChanges.pipe(
          startWith<string | AcademicDiscipline>(''),
          map(value => typeof value === 'string' ? value : value.AcademicDescription),
          map(AcademicDescription => AcademicDescription ? this._filterdiscipline(AcademicDescription) : this.alldiscipline.slice()),
        );
      }
    })


    this.service.Get('Specialization/GetSpecializationDataByDiscipline?AcademicDisplineId=' + this.myGlobal.CurAcademicDecipline, null).subscribe(data => {
      if (data != null) {
        this.allspecialization = data;
        this.filterspecialization = this.formControlspecialization.valueChanges.pipe(
          startWith<string | Specialization>(''),
          map(value => typeof value === 'string' ? value : value.SpecializationDescription),
          map(SpecializationDescription => SpecializationDescription ? this._filterSpecialization(SpecializationDescription) : this.allspecialization.slice()),
        );
      }
    })

    this.service.Get('CandidateInfo/GetFunctionData?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allfunction = data;
        this.filterfunction = this.formControlfunction.valueChanges.pipe(
          startWith<string | any>(''),
          map(value => typeof value === 'string' ? value : value.FunctionName),
          map(FunctionName => FunctionName ? this._filterFunction(FunctionName) : this.allfunction.slice()),
        );
      }
    })


    this.service.Get('IndustryTypes/GetIndustryTypes?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allsection = data;
        this.filtersection = this.formControlsection.valueChanges.pipe(
          startWith<string | any>(''),
          map(value => typeof value === 'string' ? value : value.IndustryType),
          map(IndustryType => IndustryType ? this._filterSection(IndustryType) : this.allsection.slice()),
        );
      }
    })

    //this.allJobRole = this.service.Get('JobRole/GetJobRole?Id=0', null);
    //this.allQualification = this.service.Get('Qualification/GetQualification?Id=0', null);
    //this.allCities = this.service.Get('City/GetCity?Id=0', null);
    //this.allfunction = this.service.Get('CandidateInfo/GetFunctionData?Id=0', null);
    //.allsection = this.service.Get('IndustryTypes/GetIndustryTypes?Id=0', null)
    //this.alldiscipline = this.service.Get('AcademicDiscipline/GetAcademicDisciplineByQualification?QualificationId=' + this.myGlobal.CurQualification, null);
    ///this.allspecialization = this.service.Get('Specialization/GetSpecializationDataByDiscipline?AcademicDisplineId=' + this.myGlobal.CurAcademicDecipline, null);

    this.candidateForm.controls["PageSearch"].setValue(this.myGlobal.FindCandidatePage);
    this.candidateForm.controls["JobRoleId"].setValue(this.myGlobal.CurJobRole);
    this.candidateForm.controls["CityId"].setValue(this.myGlobal.CurCity);
    this.candidateForm.controls["QualificationId"].setValue(this.myGlobal.CurQualification);
    this.candidateForm.controls["AcademicDisciplineId"].setValue(this.myGlobal.CurAcademicDecipline);
    this.candidateForm.controls["SpecializationId"].setValue(this.myGlobal.CurSpecialization);
    this.candidateForm.controls["FunctionId"].setValue(this.myGlobal.CurFunction);
    this.candidateForm.controls["SectionId"].setValue(this.myGlobal.CurIndustryType);


  }

  pageChanged(value: any) {
    this.myGlobal.FindCandidatePage = value;
    this.search();
  }

  private _filterRole(JobRole: string): JobRole[] {
    const filterValue = JobRole.toLowerCase();
    return this.allJobRole.filter(
      option => option.JobRole?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForRole(value: any) {
    return value ? value.JobRole : undefined;
  }


  private _filtercity(CityName: string): cities[] {
    const filterValue = CityName.toLowerCase();
    return this.allCities.filter(
      option => option.CityName?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForCity(value: any) {
    return value ? value.CityName : undefined;
  }



  private _filterQuali(Qualification: string): Qualification[] {
    const filterValue = Qualification.toLowerCase();
    return this.allQualification.filter(
      option => option.Qualification?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForQuli(value: any) {
    return value ? value.Qualification : undefined;
  }

  private _filterdiscipline(AcademicDescription: string): AcademicDiscipline[] {
    const filterValue = AcademicDescription.toLowerCase();
    return this.alldiscipline.filter(
      option => option.AcademicDescription?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnFordiscipline(value: any) {
    return value ? value.
      AcademicDescription : undefined;
  }

  private _filterSpecialization(SpecializationDescription: string): Specialization[] {
    const filterValue = SpecializationDescription.toLowerCase();
    return this.allspecialization.filter(
      option => option.SpecializationDescription?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForSpecialization(value: any) {
    return value ? value.
      SpecializationDescription : undefined;
  }

  private _filterFunction(FunctionName: string): any[] {
    const filterValue = FunctionName.toLowerCase();
    return this.allfunction.filter(
      option => option.FunctionName?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForFunction(value: any) {
    return value ? value.
      FunctionName : undefined;
  }

  private _filterSection(IndustryType: string): any[] {
    const filterValue = IndustryType.toLowerCase();
    return this.allsection.filter(
      option => option.IndustryType?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForSection(value: any) {
    return value ? value.IndustryType : undefined;
  }

  refreshList() {
    this.service.Post('FindCandidate/GetFilteredDataOfCandidate?userId=' + this.myGlobal.getuserId() + '&usertype='
      + this.myGlobal.GetUserType() + '&jobrole=' + this.myGlobal.CurJobRole + '&city=' + this.myGlobal.CurCity + '&qualificationId=' + this.myGlobal.CurQualification +
      '&specializationId=' + this.myGlobal.CurSpecialization + '&academicDisciplineId=' + this.myGlobal.CurAcademicDecipline +
      '&function1=' + this.myGlobal.CurFunction + '&section1=' + this.myGlobal.CurIndustryType + '&SearchEmail=' + this.myGlobal.FindCandidateEmail + '&SearchMobileNumber=' + this.myGlobal.FindCandidateMobileno
      + '&pageno=' + this.myGlobal.FindCandidatePage + '&pagesize=' + this.pagesize, null).subscribe(data => {
        this.candidatsInfo = data[0];
        this.datapageInfo = data[1];
        this.totalItems = this.datapageInfo[0].length;
        this.totalpages = Math.ceil(this.totalItems / 10);
        this.displaytotalpage = this.totalpages - 1
      },
        (error) => {
          this.toastr.error(error.error);
        });
  }

  search() {

    // this.myGlobal.getuserId();
    // this.dataFilter.push(this.myGlobal.GetUserType());
    // this.dataFilter.push(this.myGlobal.CurJobRole);
    // this.dataFilter.push(this.myGlobal.CurCity);
    // this.dataFilter.push(this.myGlobal.CurQualification);
    // this.dataFilter.push(this.myGlobal.CurSpecialization);
    // this.dataFilter.push(this.myGlobal.CurAcademicDecipline);
    // this.dataFilter.push(this.myGlobal.CurFunction);
    // this.dataFilter.push(this.myGlobal.CurIndustryType)
    // this.dataFilter.push(this.myGlobal.FindCandidatePage);
    // this.dataFilter.push(this.pagesize);
    this.refreshList();
    this.candidateForm.controls["PageSearch"].setValue(this.myGlobal.FindCandidatePage );
  }

  GOTO() {
    var _pageNumber = this.dataFilter.length - 2;
    var _pageSize = this.dataFilter.length - 1;
    delete this.dataFilter[_pageNumber];
    delete this.dataFilter[_pageSize];

    this.myGlobal.FindCandidatePage;
    this.pagesize;
    // this.service.Post('FindCandidate/GetFilteredDataOfCandidate', this.dataFilter).subscribe(data => {
    //   this.candidatsInfo = data[0];
    //   this.datapageInfo = data[1];
    //   this.totalItems = this.datapageInfo[0].length;
    // },
    //   (error) => {
    //     this.toastr.error(error.error);
    //   });
    this.refreshList();
  }

  Previous() {
    var _pageNumber = this.dataFilter.length - 2;
    var _pageSize = this.dataFilter.length - 1;
    delete this.dataFilter[_pageNumber];
    delete this.dataFilter[_pageSize];

    if (this.myGlobal.FindCandidatePage > 0) {
      this.myGlobal.FindCandidatePage = this.myGlobal.FindCandidatePage - 1
      this.candidateForm.controls["PageSearch"].setValue(this.myGlobal.FindCandidatePage );
      this.myGlobal.FindCandidatePage;
      this.pagesize;
      this.refreshList();
    } else {

    }

  }

  Next() {
    var _pageNumber = this.dataFilter.length - 2;
    var _pageSize = this.dataFilter.length - 1;
    delete this.dataFilter[_pageNumber];
    delete this.dataFilter[_pageSize];
    if (this.displaytotalpage > this.myGlobal.FindCandidatePage) {
      this.myGlobal.FindCandidatePage = this.myGlobal.FindCandidatePage + 1
      this.candidateForm.controls["PageSearch"].setValue(this.myGlobal.FindCandidatePage);
      this.myGlobal.FindCandidatePage;
      this.pagesize;
      this.refreshList();
    }
  }

  GoToPage(event: Event) {

    var value = (event.target as HTMLInputElement).value;
    if (+value > this.totalpages
      || +value < 0) {
      this.toastr.success('Page no Should be between : 0 And ' + this.totalpages);
    }
    else {
      this.myGlobal.FindCandidatePage = +value;

      var _pageNumber = this.dataFilter.length - 2;
      var _pageSize = this.dataFilter.length - 1;
      delete this.dataFilter[_pageNumber];
      delete this.dataFilter[_pageSize];

      this.myGlobal.FindCandidatePage;
      this.pagesize;
    }

  }

 SearchEmail(event: KeyboardEvent) {
  if (event.key === 'Escape' || event.key === 'Esc') {
    this.myGlobal.FindCandidateEmail = ''
    this.myGlobal.FindCandidatePage = 1
    this.candidateForm.controls["PageSearch"].setValue(this.myGlobal.FindCandidatePage);
    this.candidateForm.controls["SearchEmail"].setValue('');
    this.refreshList();
  }
else

{
  this.myGlobal.FindCandidateEmail = (event.target as HTMLInputElement).value;
  this.refreshList();
}
    
   
}

SearchMobileNumber(event: KeyboardEvent) {
  if(event.key === 'Escape' || event.key === 'Esc')
  {
    this.myGlobal.FindCandidateMobileno = ''
    this.myGlobal.FindCandidatePage = 1
    this.candidateForm.controls["PageSearch"].setValue(this.myGlobal.FindCandidatePage);
    this.candidateForm.controls["SearchMobileNo"].setValue('');
    this.refreshList();
  }
  this.myGlobal.FindCandidateMobileno = (event.target as HTMLInputElement).value;
  this.refreshList();
 
}

  onchangeCity(city: any) {
    this.myGlobal.CurCity = city;
  }

  onchangeJobRole(jobrole: any) {
    this.myGlobal.CurJobRole = jobrole;
  }

  onchangeSpecialization(specialization: any) {
    this.myGlobal.CurSpecialization = specialization;
  }

  onchangeAcademicDiscipline(AcademicDescription: any) {
    this.myGlobal.CurAcademicDecipline = AcademicDescription;
    //this.allspecialization = this.service.Get('Specialization/GetSpecializationDataByDiscipline?AcademicDisplineId=' + this.myGlobal.CurAcademicDecipline, null);
    this.service.Get('Specialization/GetSpecializationDataByDiscipline?AcademicDisplineId=' + this.myGlobal.CurAcademicDecipline, null).subscribe(data => {
      if (data != null) {
        this.allspecialization = data;
        this.filterspecialization = this.formControlspecialization.valueChanges.pipe(
          startWith<string | Specialization>(''),
          map(value => typeof value === 'string' ? value : value.SpecializationDescription),
          map(SpecializationDescription => SpecializationDescription ? this._filterSpecialization(SpecializationDescription) : this.allspecialization.slice()),
        );
      }
    })

  }

  onchangeQualification(Qualification: any) {
    this.myGlobal.CurQualification = Qualification;

    this.service.Get('AcademicDiscipline/GetAcademicDisciplineByQualification?QualificationId=' + this.myGlobal.CurQualification, null).subscribe(data => {
      if (data != null) {
        this.alldiscipline = data;
        this.filterdiscipline = this.formControldiscipline.valueChanges.pipe(
          startWith<string | AcademicDiscipline>(''),
          map(value => typeof value === 'string' ? value : value.AcademicDescription),
          map(AcademicDescription => AcademicDescription ? this._filterdiscipline(AcademicDescription) : this.alldiscipline.slice()),
        );
      }
    })
  }



  onchangeFunction(function1: any) {
    this.myGlobal.CurFunction = function1;
  }

  onchangeSection(section: any) {
    this.myGlobal.CurIndustryType = section;
  }

  viewResume(row: any) {
    this.router.navigate(['viewresume', row.UserId]);
  }

  removefilter() {
    this.myGlobal.CurJobRole = 0;
    this.myGlobal.CurCity = 0;
    this.myGlobal.CurQualification = 0;
    this.myGlobal.CurSpecialization = 0;
    this.myGlobal.CurAcademicDecipline = 0;
    this.myGlobal.CurFunction = 0;
    this.myGlobal.CurIndustryType = 0;
    this.myGlobal.FindCandidatePage = 1;
    this.JobRoleId = 0
    this.myGlobal.FindCandidateEmail = '';
    this.myGlobal.FindCandidateMobileno = '';
    this.formControlsection = new FormControl();
    this.formControlfunction = new FormControl();
    this.formControlspecialization = new FormControl();
    this.formControldiscipline = new FormControl();
    this.formControlQuli = new FormControl();
    this.formControlcity = new FormControl();
    this.formControlObj = new FormControl();

    // this.dataFilter = [];
    // this.dataFilter.push(this.myGlobal.getuserId());
    // this.dataFilter.push(this.myGlobal.GetUserType());
    // this.dataFilter.push(this.myGlobal.CurJobRole);
    // this.dataFilter.push(this.myGlobal.CurCity);
    // this.dataFilter.push(this.myGlobal.CurQualification);
    // this.dataFilter.push(this.myGlobal.CurSpecialization);
    // this.dataFilter.push(this.myGlobal.CurAcademicDecipline);
    // this.dataFilter.push(this.myGlobal.CurFunction);
    // this.dataFilter.push(this.myGlobal.CurIndustryType)
    // this.dataFilter.push(this.myGlobal.FindCandidatePage);
    // this.dataFilter.push(this.pagesize);
    this.candidateForm.reset();
    this.refreshList();
    this.service.Get('JobRole/GetJobRole?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allJobRole = data;
        this.filterJobRole = this.formControlObj.valueChanges.pipe(
          startWith<string | JobRole>(''),
          map(value => typeof value === 'string' ? value : value.JobRole),
          map(JobRole => JobRole ? this._filterRole(JobRole) : this.allJobRole.slice()),
        );
      }
    })


    this.service.Get('City/GetCity?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allCities = data;
        this.filtercity = this.formControlcity.valueChanges.pipe(
          startWith<string | cities>(''),
          map(value => typeof value === 'string' ? value : value.CityName),
          map(CityName => CityName ? this._filtercity(CityName) : this.allCities.slice()),
        );
      }
    })


    this.service.Get('Qualification/GetQualification?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allQualification = data;
        this.filterQuli = this.formControlQuli.valueChanges.pipe(
          startWith<string | Qualification>(''),
          map(value => typeof value === 'string' ? value : value.Qualification),
          map(Qualification => Qualification ? this._filterQuali(Qualification) : this.allQualification.slice()),
        );
      }
    })


    this.service.Get('AcademicDiscipline/GetAcademicDisciplineByQualification?QualificationId=' + this.myGlobal.CurQualification, null).subscribe(data => {
      if (data != null) {
        this.alldiscipline = data;
        this.filterdiscipline = this.formControldiscipline.valueChanges.pipe(
          startWith<string | AcademicDiscipline>(''),
          map(value => typeof value === 'string' ? value : value.AcademicDescription),
          map(AcademicDescription => AcademicDescription ? this._filterdiscipline(AcademicDescription) : this.alldiscipline.slice()),
        );
      }
    })


    this.service.Get('Specialization/GetSpecializationDataByDiscipline?AcademicDisplineId=' + this.myGlobal.CurAcademicDecipline, null).subscribe(data => {
      if (data != null) {
        this.allspecialization = data;
        this.filterspecialization = this.formControlspecialization.valueChanges.pipe(
          startWith<string | Specialization>(''),
          map(value => typeof value === 'string' ? value : value.SpecializationDescription),
          map(SpecializationDescription => SpecializationDescription ? this._filterSpecialization(SpecializationDescription) : this.allspecialization.slice()),
        );
      }
    })

    this.service.Get('CandidateInfo/GetFunctionData?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allfunction = data;
        this.filterfunction = this.formControlfunction.valueChanges.pipe(
          startWith<string | any>(''),
          map(value => typeof value === 'string' ? value : value.FunctionName),
          map(FunctionName => FunctionName ? this._filterFunction(FunctionName) : this.allfunction.slice()),
        );
      }
    })

    this.service.Get('IndustryTypes/GetIndustryTypes?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allsection = data;
        this.filtersection = this.formControlsection.valueChanges.pipe(
          startWith<string | any>(''),
          map(value => typeof value === 'string' ? value : value.IndustryType),
          map(IndustryType => IndustryType ? this._filterSection(IndustryType) : this.allsection.slice()),
        );
      }
    })
    this.candidateForm.controls["PageSearch"].setValue(this.myGlobal.FindCandidatePage + 1);

  }

  Whatsappmsg(row) {
    var wpmsg = new Wpmsginf();
    const dialogRef = this.dialog.open(GenralDialogComponent, {
      disableClose: true,
    });

    wpmsg.MobileNo = row.MobileNo
    dialogRef.afterClosed().subscribe(dialogResult => {
      wpmsg.Message = dialogResult.Message;
      this.Service.Post('WpMessage/SendMEssage?mobileNumber=' + wpmsg.MobileNo + '&msgText=' + wpmsg.Message, null).subscribe(
        (res) => {
          this.toastr.success(res);
        },
        (error) => {
          this.toastr.error(error.error);
        });
    })

  }
  ngOnDestroy(): void {

  }

}
