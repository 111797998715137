import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { FooterStyleOneComponent } from './components/common/footer-style-one/footer-style-one.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-two/navbar-style-two.component';
import { NavbarStyleThreeComponent } from './components/common/navbar-style-three/navbar-style-three.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';
import { LoginComponent } from './components/pages/login/login.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { EmployersComponent } from './components/pages/employers/employers.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { AboutComponent } from './components/pages/about/about.component';
import { JobsComponent } from './components/pages/jobs/jobs.component';
import { JobDetailsComponent } from './components/pages/job-details/job-details.component';
import { PostAJobComponent } from './components/pages/post-a-job/post-a-job.component';
import { CandidatesComponent } from './components/pages/candidates/candidates.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { EmployersRegisterComponent } from './components/pages/employers-register/employers-register.component';
import { CompanyCreateComponent } from './components/pages/companys/company-create/company-create.component';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from './config.service';
import { AuthService } from './auth.service';
import { UsersComponent } from './components/pages/User/users.component';
import { CountryComponent } from './components/pages/Configuration/Country/country.component';
import { AddCountryComponent } from './components/pages/Configuration/Country/Add-Edit Country/add-country.component';
import { StateComponent } from './components/pages/Configuration/State/state.component';
import { AddStateComponent } from './components/pages/Configuration/State/add-state/add-state.component';
import { CityComponent } from './components/pages/Configuration/City/city.component';
import { AddCityComponent } from './components/pages/Configuration/City/add-city/add-city.component';
import { CurrencyComponent } from './components/pages/Configuration/Currency/currency.component';
import { AddCurrencyComponent } from './components/pages/Configuration/Currency/addcurrency/addcurrency.component';
import { IndustryTypeComponent } from './components/pages/Configuration/IndustryType/industrytype.component';
import { AddIndustrytypeComponent } from './components/pages/Configuration/IndustryType/addindustrytype/addindustrytype.component';
import { JobRoleComponent } from './components/pages/Configuration/JobRole/jobrole.component';
import { AddJobRoleComponent } from './components/pages/Configuration/JobRole/addjobrole/addjobrole.component';
import { LanguagesComponent } from './components/pages/Configuration/Languages/languages.component';
import { AddLanguagesComponent } from './components/pages/Configuration/Languages/addlanguages/addlanguages.component';
import { QualificationComponent } from './components/pages/Configuration/Qualification/qualification.component';
import { AddQualificationComponent } from './components/pages/Configuration/Qualification/addqualification/addqualification.component';
import { SkillComponent } from './components/pages/Configuration/Skills/skills.component';
import { AddSkillComponent } from './components/pages/Configuration/Skills/addskills/addskills.component';
import { SearchJobsComponent } from './components/SearchJobs/SearchJobs.component';
import { WorkFromHomeJobComponent } from './components/pages/WorkFromHomeJob/WorkFromHomeJob.component';
import { CreateCandidateComponent } from './components/pages/employers-register/candidate/createcandidate.component';
import { CreateCollegeComponent } from './components/pages/College/createcollege.component';
import { CompanySignupComponent } from './components/pages/companys/companysignup/companysignup.component';
import { ShortListedCandidateForOwnerComponent } from './components/pages/ShortListedCandidateForOwner/shortlistedcandidateforowner.component';
// import { NgxDocViewerModule } from 'ngx-doc-viewer';  

// angular Material Imports
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';

import { UserTableComponent } from './components/pages/User/UserTable/usertable.component';
import { CompanyTableComponent } from './components/pages/companys/companytable/companytable.component';
import { ApplyJobComponent } from './components/pages/jobs/applyjob/applyjob.component';
import { ManageJobComponent } from './components/pages/jobs/managejobstable/managejobs.component';
import { CandidateTableComponent } from './components/pages/employers-register/candidate/candidatetable/candidatetable.component';
import { EditJobPostComponent } from './components/pages/post-a-job/edit-job-post/edit-job-post.component';
import { ViewResumeComponent } from './components/pages/candidates/ViewResume/viewresume.component';
import { UserHomeComponent } from './components/pages/Users/UserHome/userhome.component';
import { OwnerHomeComponent } from './components/pages/OwnerHome/ownerhome.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { EditCandidateComponent } from './components/pages/employers-register/EditCandidate/editcandidate.component';
import { CompanyHomeComponent } from './components/pages/companys/CompanyHome/companyhome.component';
import { ConfigDialogComponent } from './components/pages/config-dialog/confing-dialog.component';
import { ForgotPasswordComponent } from './components/pages/ForgotPassword/forgotpassword.component';
import { KeshavDashboardComponent } from './components/pages/Keshavdashboard/keshavdashboard.component';
import { GenralDialogComponent } from './components/pages/input -dialog/genral-dialog.component';
import { UserPointsComponent } from './components/pages/User/UserPoints/userpoints.component';
import { InputDialogComponent } from './components/pages/input -dialog/input-dialog.component';
import { WhatsappReceiveMsgComponent } from './components/pages/candidates/WhatsappReceiveMsg/whatsappreceivemsg.component';


import {MatSelectModule} from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { UserLastLoginComponent } from './components/pages/User/UserLastLogin/userlastlogin.component';
import { RecruiterHomeComponent } from './components/pages/Recruiter/recruiterhome.component';
import { OverdueJobComponent } from './components/pages/jobs/OverdueJob/overduejob.component';
import { JoiningDateComponent } from './components/pages/job-details/JoiningDate/add-joiningdate.component';
import { CandidateReportComponent } from './components/pages/CandidateReport/candidatereport.component';
import { CompanyEditComponent } from './components/pages/companys/companytable/Edit-Company/edit-company.component';
import { CompanyViewComponent } from './components/pages/companys/companytable/view-company/view-company.component';
import { BlacklistCandidateComponent } from './components/pages/config-dialog/BlackListCandidate/blacklistcandidate.component';
import { AddAcademicDescriptionComponent } from './components/pages/Configuration/Qualification/addacademicDiscipline/addacademicdiscipline.component';
import { AddSpecializationComponent } from './components/pages/Configuration/Qualification/addSpecialization/addSpecialization.component';
import { SpecializationComponent } from './components/pages/Configuration/Qualification/addSpecialization/specialization.component';
import { AcademicdisciplineComponent } from './components/pages/Configuration/Qualification/addacademicDiscipline/academicdiscipline.component';
import { SpinnerOverlayService } from './components/pages/Spinner/spinner-overlay.service';
import { SpinnerInterceptor } from './components/pages/Spinner/spinner-interceptor.service';
import { SpinnerOverlayComponent } from './components/pages/Spinner/spinner.component';
import { ViewCandidateComponent } from './components/pages/employers-register/candidate/candidatetable/View/ViewCandidate.component';
import { ClientCreateComponent } from './components/pages/Client/client-register.component';
import { ClientComponent } from './components/pages/Client/ClientTable/clienttable.component';
import { ClientEditComponent } from './components/pages/Client/ClientTable/EditClient/editclient.component';
import { ClientViewComponent } from './components/pages/Client/ClientTable/ViewClient/viewclient.component';
import { FunctionComponent } from './components/pages/Configuration/Function/function.component';
import { AddFunctionComponent } from './components/pages/Configuration/Function/addfunction.component.ts/addfunction.component';
import { ViewWhatappMessageComponent } from './components/pages/employers-register/candidate/candidatetable/viewwpmsg/viewwpmessage.component';

import { MatAutocompleteModule } from '@angular/material/autocomplete';

import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { NewWhatsappSendMsgComponent } from './components/pages/candidates/newwpsendmsg/newwhatsappsendmsg.component';
import { WPReciveComponent } from './components/pages/candidates/wpreceivemsg/wprecivemsg.component';
import { WhatsappSendAndReceiveMsgComponent } from './components/pages/candidates/sendandreceivemsg/sendandreceivemsg.component';
import { WhatsappSendMsgComponent } from './components/pages/candidates/WhatsappSendMsg/whatsappsendmsg.component';
import { MatTooltip } from '@angular/material/tooltip';

export function init_app(configService: ConfigService) {
  return () => configService.init();
}
@NgModule({
  declarations: [
    AppComponent,
    HomeOneComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    PreloaderComponent,
    NavbarStyleOneComponent,
    FooterStyleOneComponent,
    NavbarStyleTwoComponent,
    NavbarStyleThreeComponent,
    FooterStyleTwoComponent,
    LoginComponent,
    RegisterComponent,
    EmployersComponent,
    TestimonialsComponent,
    FaqComponent,
    ComingSoonComponent,
    ErrorComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    AboutComponent,
    JobsComponent,
    JobDetailsComponent,
    PostAJobComponent,
    CandidatesComponent,
    ContactComponent,
    EmployersRegisterComponent,
    CompanyCreateComponent,
    UsersComponent,
    CountryComponent,
    AddCountryComponent,
    StateComponent,
    AddStateComponent,
    CityComponent,
    AddCityComponent,
    CurrencyComponent,
    AddCurrencyComponent,
    IndustryTypeComponent,
    AddIndustrytypeComponent,
    JobRoleComponent,
    AddJobRoleComponent,
    LanguagesComponent,
    AddLanguagesComponent,
    QualificationComponent,
    AddQualificationComponent,
    SkillComponent,
    AddSkillComponent,
    SearchJobsComponent,
    WorkFromHomeJobComponent,
    CreateCandidateComponent,
    CreateCollegeComponent,
    CompanySignupComponent,
    UserTableComponent,
    CompanyTableComponent,
    ApplyJobComponent,
    ManageJobComponent,
    CandidateTableComponent,
    EditJobPostComponent,
    ViewResumeComponent,
    UserHomeComponent,
    OwnerHomeComponent,
    ShortListedCandidateForOwnerComponent,
    EditCandidateComponent,
    CompanyHomeComponent,
    ConfigDialogComponent,
    ForgotPasswordComponent,
    GenralDialogComponent,
    KeshavDashboardComponent,
    UserPointsComponent,
    InputDialogComponent,
    WhatsappReceiveMsgComponent,
    WhatsappSendMsgComponent,
    UserLastLoginComponent,
    RecruiterHomeComponent,
    OverdueJobComponent,
    JoiningDateComponent,
    CandidateReportComponent,
    CompanyEditComponent,
    CompanyViewComponent,
    BlacklistCandidateComponent,
    AddAcademicDescriptionComponent,
    AddSpecializationComponent,
    SpecializationComponent,
    AcademicdisciplineComponent,
    SpinnerOverlayComponent,
    ViewCandidateComponent,
    ClientCreateComponent,
    ClientComponent,
    ClientEditComponent,
    ClientViewComponent,
    FunctionComponent,
    AddFunctionComponent,
    ViewWhatappMessageComponent,
    NewWhatsappSendMsgComponent,
    WPReciveComponent,
    WhatsappSendAndReceiveMsgComponent,
   
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(
      {
        positionClass:'toast-center',
        timeOut: 6000,
        // autoDismiss:false,
        // disableTimeOut:true,
        // closeButton:true
      }
    ),
    NgbModule,
    NgbDatepickerModule,
    // Material
    MatPaginatorModule,
    MatTableModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatGridListModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule   ,
    MatSelectModule,
    MatCheckboxModule ,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
   
  
  ],
  providers: [ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [ConfigService],
      multi: true
    },
    SpinnerInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true
    },
    // {
    //   provide: LocationStrategy, useClass: HashLocationStrategy,
    // },
    AuthService
   
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
